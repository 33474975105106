<template>
    <div class="row">
        <div class="col-lg-12 mb-lg-0 mb-4">
          <div class="card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-lg-12">
                  <div class="d-flex flex-column h-100">
                    <h5 class="font-weight-bolder">Change Password</h5>
                    <form action="">
                      <soft-input
                        id="password"
                        type="password"
                        placeholder="New Password"
                        isRequired="true"
                      />
                      <soft-input
                        id="confirm_password"
                        type="password"
                        placeholder="Confirm Password"
                        isRequired="true"
                      />
                    
                      <soft-button
                          class="my-4 mb-2"
                          variant="fill"
                          color="info"
                          @click="sendMail"
                          type="button"
                          
                          >Change Password
                        </soft-button>
  
  
                    </form>
                    <span v-if="validationerror" :style="{ color: 'red' }"> <small>Validation Error</small></span>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
  
      </div>
  </template>
  
  <script>
  import SoftInput from "@/components/SoftInput.vue";
  import SoftButton from "@/components/SoftButton.vue";
  import Swal from 'sweetalert2';
  import axios from "axios";
  import $ from 'jquery';
  export default {
    components: {
  
      SoftInput,
      SoftButton
  
    },
    data() {
      return {
        formData: {
          password: "",
          confirm_password: "",
        },
        validationerror: false,
      };
    },
    methods:{
     async sendMail()
      {
        
        const token =  localStorage.getItem('token');
        const formData = {
            password: $('#password').val(),
            confirm_password: $('#confirm_password').val(),
            token:token
        };

        if(formData.password !== formData.confirm_password) return this.validationerror =true;
        else  this.validationerror =false;
        try {
              const response = await Swal.fire({
                title: 'Are you sure you want to update your password?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, update it!',
                allowOutsideClick: false,
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                  try {
                    const result = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/change_password_employee`, formData, {
                      headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                      },
                    });
  
                    if (result.data.status === 200) {
                      return result.data;
                    } else {
                      throw new Error(result.data.message || 'Failed to send email');
                    }
                  } catch (error) {
                    Swal.showValidationMessage(error.message);
                  }
                },
              });
  
              if (response.value) {
                Swal.fire({ title: response.value.message, icon: 'success' });
                $('#password').val("");
                $('#confirm_password').val("");
                
              } else if (response.dismiss === Swal.DismissReason.cancel) {
                console.log('Email sending cancelled');
              }
            } catch (error) {
              // Handle unexpected errors
              console.error(error);
            }
  
      }
    }
  
  };
  </script>
  