<template>
  <div class="py-4 container-fluid">
    
    <div class="row">
      <div class="text-end mb-2">
        <soft-button color="info" size="lg"  @click="btn_time_entry"
            >Time Entry</soft-button
          >
      </div>
      <div class="text-start mb-2" >
        <label for="selectMonth">Select Month:</label>
          <select  v-model="selectedMonth" id="selectMonth" @change="monthly_view"  >
            <option value="all" selected>All</option>
            <option v-for="month in months" :key="month" :value="month">
              {{ new Date(month).toLocaleDateString('en-US', { year: 'numeric', month: 'long' }) }}
            </option>
          </select>
      </div>
    </div>
 
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Client
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Time In
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Time Out
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Total Hours
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Specifications
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
              >
                Status
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="timesheet in filteredTimesheets" :key="timesheet.id">
              <td>
                <div class="d-flex px-2">
                  <div>
                  
                  </div>
                  <div class="my-auto">
                    <h6 class="mb-0 text-sm"><b>{{ timesheet.client.first_name  }} {{ timesheet.client.last_name }}</b></h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="d-flex px-2">
                  <div>
                  
                  </div>
                  <div class="my-auto">
                    <p class="mb-0 text-sm">{{ timesheet.start_date  }} {{ timesheet.start_time }}</p>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-sm mb-0">{{ timesheet.end_date  }} {{ timesheet.end_time }}</p>
              </td>
              <td>
                <p class="text-sm mb-0">{{ timesheet.total_hours  }} </p>
              </td>
              <td>
                <span class="text-xs font-weight-bold">{{ timesheet.specification?? "No specifications"  }}</span>
              </td>
              <td class="align-middle text-center">
                <div v-if="timesheet.status == 0" >
                  <span class="text-xs font-weight-bold">Pending</span>
                </div>
                <div v-else-if="timesheet.status == 1" >
                  <span class="text-xs font-weight-bold">Approved</span>
                </div>
                <div v-else>
                  <span class="text-xs font-weight-bold">Declined</span>
                </div>
              </td>
              <td class="align-middle">
              
              </td>
            </tr>
      
          </tbody>
        </table>
    
    </div>
    <modal :is-open="isModalOpen" @close="closeModal"/>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import Modal from "@/views/components/time_entry_modal.vue";
import axios from 'axios';
import Swal from "sweetalert2";
export default {
  name: "tables",
  components: {
    SoftButton,
    Modal,
  },
  data()
        {
           return {
            isModalOpen: false,
            timesheets:[],
            selectedMonth: 'all',
            months: [],
            filteredTimesheets :[]
           }
        },
  watch: {
    selectedMonth: 'monthly_view',
  },
  methods: {
    btn_time_entry()
    {
      if(!localStorage.getItem("selectedClientId"))
        {
          return Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `Please Select A client Before Time Entry`,
             
            });
        }
      this.isModalOpen = true;
    },
    closeModal()
    {
      this.isModalOpen = false;
      this.fetch_timesheets();
    },
    async fetch_timesheets()
    {
      const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/timesheets`, this.formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          });
          this.timesheets = response.data.data;
          this.monthly_view();
    },
    monthly_view()
    {
      if (this.selectedMonth === 'all') {
        this.filteredTimesheets = this.timesheets;
        return;
      }

      const [year, month] = this.selectedMonth.split('-');
      const startingDate = new Date(`${year}-${month}-01`);
      const endingDate = new Date(startingDate.getFullYear(), startingDate.getMonth() + 1, 0);

      this.filteredTimesheets = this.timesheets.filter((timesheet) => {
        const timesheetStartDate = new Date(timesheet.start_date);
        const timesheetEndDate = new Date(timesheet.end_date);

        return (
          timesheetStartDate >= startingDate &&
          timesheetStartDate <= endingDate &&
          timesheetEndDate >= startingDate &&
          timesheetEndDate <= endingDate
        );
      });
    }

   
  },
  mounted() {
          this.fetch_timesheets();
          const currentYear = new Date().getFullYear();
          const currentMonth = new Date().getMonth();
          const monthsToInclude = 6;
          for (let i = currentMonth; i > currentMonth - monthsToInclude; i--) {
            const prevMonth = i <= 0 ? i + 12 : i; 
            const formattedMonth = prevMonth < 10 ? `0${prevMonth}` : `${prevMonth}`;
            this.months.unshift(`${currentYear - 1}-${formattedMonth}`);
          }

          for (let i = currentMonth; i <= currentMonth + monthsToInclude; i++) {
            const nextMonth = i > 12 ? i - 12 : i;
            const formattedMonth = nextMonth < 10 ? `0${nextMonth}` : `${nextMonth}`;
            this.months.push(`${currentYear}-${formattedMonth}`);
          }

      console.log(this.months)


        },

};
</script>
