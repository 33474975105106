import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import TimeSheet from "@/views/TimeSheet.vue";
import Message from "@/views/Message.vue";
import change_password from "@/views/change_password.vue";
import VirtualReality from "@/views/VirtualReality.vue";
import Profile from "@/views/Profile.vue";
import Rtl from "@/views/Rtl.vue";
import SignIn from "@/views/SignIn.vue";
import SwitchClient from "@/views/switch_client.vue";
import forgot_password from "@/views/forgot_password.vue";
import SignUp from "@/views/SignUp.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/TimeSheet",
    name: "TimeSheet",
    component: TimeSheet,
  },
  {
    path: "/Message",
    name: "Message",
    component: Message,
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/rtl-page",
    name: "Rtl",
    component: Rtl,
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    component: SignUp,
  },
  {
    path: "/forgot_password",
    name: "forgot Password",
    component: forgot_password,
  },
  {
    path: "/change_password",
    name: "Change Password",
    component: change_password,
  },
  {
    path: "/swith_client",
    name: "Swith Client",
    component: SwitchClient,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  const tokenExpiration = localStorage.getItem("tokenExpiration");
  const isSignInRoute = to.path === "/sign-in";
  const isforgot_pass = to.path === "/forgot_password";
  // Check if the token exists and is not expired
  if (isSignInRoute || isforgot_pass) {
    next();
  } else {
    // Check if the token exists and is not expired
    if (token && tokenExpiration && new Date().getTime() < tokenExpiration) {
      const new_expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000;
      localStorage.setItem('tokenExpiration', new_expirationTime);
      next(); // Continue with the navigation
    } else {
      next("/sign-in"); // Redirect to the sign-in route
    }
  }
});


export default router;
