<template>
  <nav
    class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl"
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs :currentPage="currentRouteName" :textWhite="textWhite" />
      <div
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="this.$store.state.isRTL ? 'px-0' : 'me-sm-4'"
        id="navbar"
      >
        <div
          class="pe-md-3 d-flex align-items-center"
          :class="this.$store.state.isRTL ? 'me-md-auto' : 'ms-md-auto'"
        >
          <div class="input-group">
            <span class="input-group-text text-body">
              <i class="fas fa-search" aria-hidden="true"></i>
            </span>
            <input type="text" class="form-control" />
          </div>
        </div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-flex align-items-center">
            <div class="dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                role="button"
                id="userDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  :src="avatarUrl"
                  alt="User Avatar"
                  class="rounded-circle"
                  width="60"
                  height="60"
                />
              </a>
              <ul class="dropdown-menu" aria-labelledby="userDropdown">
                <li>
                  <a class="dropdown-item" href="#" @click="signout">Sign Out</a>
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#switchClientModal"
                  >
                    Switch Client
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
              href="#"
              @click="toggleSidebar"
              class="p-0 nav-link text-body"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div
    class="modal fade"
    id="switchClientModal"
    tabindex="-1"
    aria-labelledby="switchClientModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="switchClientModalLabel">Switch Client</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div
              v-for="client in clients"
              :key="client.id"
              class="col-12 col-md-6 col-lg-4 mb-3"
            >
              <button
                class="btn btn-primary w-100"
                @click="selectClient(client.client_id, client.client.first_name, client.client.last_name)"
              >
                {{ client.client.first_name }} {{ client.client.last_name }}
              </button>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "navbar",
  data() {
    return {
      avatarUrl: "favicon.png",
      clients: [],
    };
  },
  props: ["minNav", "textWhite"],
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),
    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },
    async signout() {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You will be signed out!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, sign me out!",
      });
      if (result.isConfirmed) {
        localStorage.removeItem("token");
        localStorage.removeItem("selectedClientId");
        localStorage.removeItem("clientName");
        localStorage.removeItem("clientLastName");
        this.$router.push({ name: "Sign In" });
      }
    },
    fetchClients() {
      fetch(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/client_fetch`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.clients = data.clients;
        })
        .catch((error) => {
          console.error("Error fetching clients:", error);
        });
    },
    selectClient(clientId, clientName, clientLastName) {
      localStorage.setItem("selectedClientId", clientId);
      localStorage.setItem("clientName", clientName);
      localStorage.setItem("clientLastName", clientLastName);
      location.reload();
    },
  },
  components: {
    Breadcrumbs,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  mounted() {
    this.fetchClients();
  },
  updated() {
    const navbar = document.getElementById("navbarBlur");
    window.addEventListener("scroll", () => {
      if (window.scrollY > 10 && this.$store.state.isNavFixed) {
        navbar.classList.add("blur", "position-sticky", "shadow-blur");
      } else {
        navbar.classList.remove("blur", "position-sticky", "shadow-blur");
      }
    });
  },
};
</script>