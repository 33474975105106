<template>
    <div v-if="isOpen" class="modal-overlay">
      <div class="modal-container modal-lg">
    
        <div class="col-12 text-end">
        <soft-button color="danger" size="sm"  @click="closeModal"
            >X</soft-button
          >

        <div class="modal-header">
          Time Entry
        </div>
      </div>
        <div class="modal-body">
            <div class="form_container">
                <form action="">
                  <label>Service Code</label>
                  <soft-input
                      id="service_code"
                      type="number"
                      placeholder="Service Code"
                      name="service_code"
                      size="service_code"
                    />
                    <label>Start Date</label>
                    <soft-input
                      id="start_date"
                      type="date"
                      @change="calculate"
                      placeholder="Date Time In"
                      name="start_date"
                      size="lg"
                    />
                    <label>Start Time</label>
                    <soft-input
                      id="start_time"
                      type="time"
                      @change="calculate"
                      name="start_time"
                      size="lg"
                    />
                    <label>End Date</label>
                    <soft-input
                      id="end_date"
                      type="date"
                      @change="calculate"
                      name="end_date"
                      size="lg"
                    />
                    <label>Time</label>
                    <soft-input
                      id="end_time"
                      type="time"
                      @change="calculate"
                      name="end_time"
                      size="lg"
                    />
                    <label>Total Hours</label>
                   <span class="form-control" id="Total_hrs">{{ formData.total_hours || '0.00' }} Hrs</span>
                    <label>Specifications:</label>
                    <soft-text-area
                      id="specify"
                      type="text"
                      name="specify"
                      size="lg"
                      placeholder="Please specify task that was done in this time entry"
                    />
                    <soft-button 
                    color="success" 
                    size="lg"  
                    type="button"
                    fullWidth="true"
                    @click="submit_time_entry">
                        Submit
                    </soft-button>
                </form>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import SoftInput from "@/components/SoftInput.vue";
  import SoftButton from "@/components/SoftButton.vue";
  import SoftTextArea from "@/components/SoftTextarea.vue";
  import $ from 'jquery';
  import Swal from 'sweetalert2';
  import axios from 'axios';
  import alertify from 'alertifyjs';
  export default {
    data() {
      return {
        formData: {
            start_date: null,
            start_time: null,
            end_date: null,
            end_time: null,
            service_code:null,
            total_hours: null,
            specification:null
        },
        agreeToPrivacyPolicy: false,
        validation_fail: false,
        employees: Object
      };
    },
    props: {
      isOpen: Boolean,
      title: String,
    },
    components:
    {
        SoftInput,
        SoftButton,
        SoftTextArea,

    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
      handleFileChange(event) {
       
        this.formData.files = event.target.files;
      },
     async submit_time_entry()
      {
        this.formData = {
            start_date: $('#start_date').val(),
            start_time: $('#start_time').val(),
            end_date:   $('#end_date').val(),
            end_time:   $('#end_time').val(),
            service_code:   $('#service_code').val(),
            client_id:    localStorage.getItem("selectedClientId"),
            total_hours:   this.formData.total_hours,
            specification: $('#specify').val(),
        };
        if(this.formData.total_hours<=0)
        {
            return  alertify.error('Invalid Time');
        }

        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/timesheet_entry`, this.formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          });
          
          if(response.data.status ==201)
          {
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Your work has been saved",
                showConfirmButton: false,
                timer: 1500
                });
                this.closeModal() 
          }
          if(response.data.status >400)
          {
            return alertify.error(response.data.message+" available hours for this week:"+response.data.available_hours);
          }
      },
      calculate()
      {
        this.formData = {
            start_date: $('#start_date').val(),
            start_time: $('#start_time').val(),
            end_date:   $('#end_date').val(),
            end_time:   $('#end_time').val(),
            total_hours:   null,
        };
    
        if(
            this.formData.start_date !=""&
            this.formData.start_time !=""&
            this.formData.end_date !="" &
            this.formData.end_time !=""
            )
            {
                const startDate = new Date(`${this.formData.start_date}T${this.formData.start_time}`);
                const endDate = new Date(`${this.formData.end_date}T${this.formData.end_time}`);
                const timeDifference = endDate - startDate;
                const hoursDifference = timeDifference / (1000 * 60 * 60);
                this.formData.total_hours = hoursDifference.toFixed(2);
                
             

            }
            
       
      }

    },
  };
  </script>
  
  <style scoped>
  /* Add your styling for the modal */
  .modal-overlay {
    /* Overlay styles */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-container {
    /* Modal styles */
    background: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1050;
  }
  
  .modal-header {
    /* Header styles */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-header h3 {
    margin: 0;
  }
  
  .modal-header button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  .modal-lg {
  width: 80%; /* Adjust the width as needed */
}
.alertify {
  z-index: 10001; 
}
.ajs-error {
  z-index: 10002; 
}

  </style>
  