<template>
  <div class="container my-4">
    <h2 class="mb-4">Switch Client</h2>
    <div class="row">
      <div class="col-md-4 mb-3" v-for="client in clients" :key="client.id">
        <div class="card shadow-sm">
          <div class="card-body">
            <h5 class="card-title">{{ client.client.first_name }} {{ client.client.last_name }}</h5>
            <button class="btn btn-info" @click="selectClient(client.client_id,client.client.first_name,client.client.last_name)">Select Client</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SwitchClient",
  data() {
    return {
      clients: [], 
    };
  },
  created() {
    this.fetchClients();
  },
  methods: {
    fetchClients() {
      fetch(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/client_fetch`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => response.json())
      .then(data => {
        this.clients = data.clients; 
      })
      .catch(error => {
        console.error('Error fetching clients:', error);
      });
    },
    selectClient(clientId, clientName, clientLastName) {
      localStorage.setItem('selectedClientId', clientId);
      localStorage.setItem('clientName', clientName);
      localStorage.setItem('clientLastName', clientLastName);
      this.$router.push({ name: 'Dashboard' }).then(() => {
      location.reload();
    });
    },
  },
};
</script>
<style scoped>
.container {
  max-width: 960px;
  margin: auto;
}

.card {
  transition: transform 0.2s, box-shadow 0.2s;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-title {
  margin-bottom: 20px;
  text-align: center;
}

.btn {
  margin-top: auto;
}
</style>
