<template>
  <nav aria-label="breadcrumb">
    <ol
      class="px-0 pt-1 pb-0 mb-0 bg-transparent breadcrumb"
      :class="this.$store.state.isRTL ? '' : ' me-sm-6'"
    >
      <li class="text-sm breadcrumb-item" :class="textWhite">
        <a
          v-if="this.$store.state.isRTL"
          :class="textWhite"
          class="opacity-5 ps-2"
          href="#"
        >لوحات القيادة</a>
        <a v-else :class="textWhite" class="opacity-8" href="#">Pages</a>
      </li>
      <li
        class="text-sm breadcrumb-item active"
        :class="textWhite ? 'text-white' : 'text-dark'"
        aria-current="page"
      >
        {{ currentPage }}
      </li>
    </ol>
    <h2>Client: {{ clientName }} {{ clientLastName }}</h2>
    <div>
      <button v-if="showTimeOutButton"
        class="btn mt-3 btn-warning"
        @click="timeOutEntry"
      >
        Time Out
      </button>
      <button v-if="!showTimeOutButton"
        class="btn mt-3"
        :class="timeOutButtonClass"
        @click="toggleServiceCodeInput"
      >
        {{ timeOutButtonText }}
      </button>
      <div v-if="showServiceCodeInput" class="mt-3">
        <label for="">Service Code</label>
        <input
          type="text"
          class="form-control d-inline-block w-auto"
          v-model="serviceCode"
          placeholder="Service Code"
        />
        <button class="btn btn-success ml-2 mt-3" @click="submitServiceCode">Submit</button>
      </div>
    </div>
    <div v-if="timeInHours !== null">
      <p>Current Running Time In: {{ timeInHours }} hours</p>
    </div>
    
  </nav>
</template>

<script>
import axios from 'axios';
import Swal from "sweetalert2";
export default {
  name: "breadcrumbs",
  props: {
    currentPage: {
      required: true,
    },
    textWhite: {
      type: String,
    },
  },
  data() {
    return {
      showServiceCodeInput: false,
      serviceCode: "331",
      timeInHours: null,
      timesheetID:null,
      showTimeOutButton:false
    };
  },
  computed: {
    clientName() {
      return localStorage.getItem("clientName");
    },
    clientLastName() {
      return localStorage.getItem("clientLastName");
    },
    timeOutButtonClass() {
      return this.showServiceCodeInput ? 'btn-danger' : 'btn-info';
    },
    timeOutButtonText() {
      return this.showServiceCodeInput ? 'Cancel' : 'Time In';
    },
  },
  async mounted() {
    await this.checkTimeInEntry();
    this.updateTimeInHours();
  },
  methods: {
    async checkTimeInEntry() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/check_time_in`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.timesheet) {
          this.showServiceCodeInput = false;
          this.showTimeOutButton =true;
          this.timesheetID = response.data.timesheet.id;
          this.calculateTimeInHours(response.data.timesheet.start_date, response.data.timesheet.start_time);
        } else {
          this.showTimeOutButton =false;
          this.showServiceCodeInput = false;
          this.timeInHours = null;
        }
      } catch (error) {
        console.error('Error checking time in entry:', error);
      }
    },
    calculateTimeInHours(startDate, startTime) {
      const startTimeStamp = new Date(`${startDate}T${startTime}`).getTime();
      const currentTimeStamp = new Date().getTime();
      const timeDiff = currentTimeStamp - startTimeStamp;
      this.timeInHours = Math.floor(timeDiff / (1000 * 60 * 60));
    },
    async toggleServiceCodeInput() {
      this.showServiceCodeInput = !this.showServiceCodeInput;
    },
    async timeOutEntry()
    {
      const token = localStorage.getItem('token');
      const formData = new FormData();
      formData.append('timesheet_Id', this.timesheetID);
      try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/time_out`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log(response);
        location.reload();
        this.checkTimeInEntry();
        this.showServiceCodeInput = true;
      } catch (error) {
        console.error('Error submitting service code:', error);
      }

    },
    async submitServiceCode() {
      const client_id = localStorage.getItem('selectedClientId');
      if(!client_id)
      {
       return Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `Please Select a Client to clock in`,
             
            });
        
      }
      console.log(`Service Code Submitted: ${this.serviceCode}`);
      const token = localStorage.getItem('token');
      
      const formData = new FormData();
      formData.append('service_code', this.serviceCode);
      formData.append('client_id', client_id);
      try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/time_in`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log(response);
        this.checkTimeInEntry();
        this.showServiceCodeInput = true;
      } catch (error) {
        if (error.response && error.response.status === 400) {
            console.error('Validation errors:', error.response.data.error);
            const errorMessage = error.response.data.error.client_id[0]; 
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `${errorMessage}`,
             
            });
          } else {
            console.error('Error submitting service code:', error);
          }
      }
    },
    updateTimeInHours() {
      setInterval(() => {
        if (this.showServiceCodeInput) {
          this.calculateTimeInHours(); 
        }
      }, 60000); 
    },
  },
};
</script>

<style scoped>
.mt-3 {
  margin-top: 1rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
</style>
